<template>
  <div class="home">
    <div class="banner_container">
      <swiper ref="mySwiper" :options="swiperOptions" v-if="showSwiper">
        <swiper-slide v-for="item in banners" :key="item">
          <div class="banner">
            <img :src="item" alt="banner" />
          </div>
        </swiper-slide>
      </swiper>

      <div class="my-swiper-pagination">
        <img class="left" src="../../assets/swiper-prev.png" alt="" @click="prevPage" />
        <img class="right" src="../../assets/swiper-next.png" alt="" @click="nextPage" />
      </div>
    </div>
    <div class="about_us">
      <div class="container">
        <h1>关于我们</h1>
        <p>
          卖座网隶属于深圳市华宇讯科技有限公司
          ，总部位于深圳，是一家集电影资讯、在线购票、用户互动社交、电影衍生品销售等服务的智慧电影互联网平台，提供全国超3500家影院在线选座购票服务。
          已在广州、北京、上海、深圳、武汉等地设立专业团队提供本地化服务。
          卖座网于2008年自主研发星辰电子影票系统，该系统具有独有的新型、便捷电影票务模式， 投入市场以来，得到了用户、合作影院的一致好评，市场影响力与日剧增。
          正是由于在影院的强大影响力、独特便捷的购票模式和极具优势的独家价格
          ，卖座网积极与多方合作，更从2008年开始，与腾讯携手合作，共同为亿万QQ会员用户提供优质的服务。 2014年，
          华谊兄弟以人民币2.66亿元投资卖座网，卖座网成华谊兄弟集团控股公司，成为当前具有影响力的电影O2O服务提供商之一。
        </p>
        <table>
          <tr>
            <td colspan="3" align="left">
              <img class="show_img" :src="imgUrl.aboutUsImg.one" alt="" />
            </td>
            <td colspan="3" align="center">
              <img class="show_img" :src="imgUrl.aboutUsImg.two" alt="" />
            </td>
            <td colspan="3" align="right">
              <img class="show_img" :src="imgUrl.aboutUsImg.three" alt="" />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="changeMd">
          <ul>
            <li :class="{ isActive: cur_flag == 'A' }" @click="cur_flag = 'A'">
              使用帮助
            </li>
            <li :class="{ isActive: cur_flag == 'B' }" @click="cur_flag = 'B'">
              退票服务
            </li>
            <li :class="{ isActive: cur_flag == 'C' }" @click="cur_flag = 'C'">
              服务条款
            </li>
          </ul>
        </div>
        <div class="show_md">
          <transition name="show" mode="out-in">
            <div class="md_one" v-if="cur_flag === mdData[0].flag">
              <h1>{{ mdData[0].title }}</h1>
              <ul>
                <li v-for="(item, index) in mdData[0].list" :key="index">
                  <em class="num">{{ index + 1 }}</em>
                  <span class="listTitle">{{ item.listTitle }}</span>
                  <p>{{ item.listContext }}</p>
                </li>
              </ul>
            </div>
          </transition>
          <transition name="show" mode="out-in">
            <div class="md_two" v-if="cur_flag === mdData[1].flag">
              <h1>{{ mdData[1].title }}</h1>
              <ul>
                <li v-for="(item, index) in mdData[1].list" :key="index">
                  <span class="listTitle">{{ item.listTitle }}</span>
                  <p v-html="item.listContext"></p>
                </li>
              </ul>
            </div>
          </transition>
          <transition name="show" mode="out-in">
            <div class="md_three" v-if="cur_flag === mdData[2].flag">
              <h1>{{ mdData[2].title }}</h1>
              <ul>
                <li v-for="(item, index) in mdData[2].list" :key="index">
                  <span class="listTitle">{{ item.listTitle }}</span>
                  <p v-html="item.listContext"></p>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import axios from 'axios'
export default {
  data() {
    return {
      swiperOptions: {
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      },

      imgUrl: {
        // bannerUrl:
        //   "https://mall.s.maizuo.com/ec15f7be2afc707f29cad4423809d9f7.jpg",
        aboutUsImg: {
          one: 'https://mall.s.maizuo.com/bb4e79ef1de07aab2f8ed91b7fd7e731.jpg',
          two: 'https://mall.s.maizuo.com/90b7581c565efdf1b3ed8127172d9c87.jpg',
          three: 'https://mall.s.maizuo.com/e753ab0a0709ff8a7048da1c714c5e06.jpg',
        },
      },
      cur_flag: 'A',
      mdData: [
        {
          flag: 'A',
          title: '使用帮助',
          list: [
            {
              listTitle: '什么是卖座卡?',
              listContext:
                '卖座卡是卖座网的金牌产品，是作为员工福利、赠送客户和回馈会员的理想选择，方便实用，服务尊享。适用于卖座网平台上可预订及支付的项目，包含电影票、影院小食、电影周边。',
            },
            {
              listTitle: '卖座卡可不可以延期?',
              listContext: '卖座卡背面是印有有效期的，只能在有效期内使用 ，根据购卡协议目前暂不支持延期。',
            },
            {
              listTitle: '我买了某影城的订座票，但是临时有事不能去看可以退吗?',
              listContext: '详情请见「退票服务」。',
            },
            {
              listTitle: '我有一张卖座网面额为100元的电影卡，请问可以去哪些影城使用?',
              listContext: '在全国范围内，只要跟卖座网有合作的影城都可以使用。',
            },
            {
              listTitle: '公司刚发的卡，找不到了，请问怎么办?',
              listContext: '建议您第一时间联系卖座客服4001808400进行挂失，避免资金流失。',
            },
          ],
        },
        {
          flag: 'B',
          title: '退票服务',
          list: [
            {
              listTitle: '一、卖座网仅提供因以下原因造成的用户退票：',
              listContext: `1. 影院为卖座网支持退票服务的影院；<br/>
                            2. 所退影票不属于特殊场次的影票（特殊场次会在购票时提示）；<br/>
                            3. 单个用户ID票的手机号码每个月（自然月）申请退票的订单数不超过5笔；<br/>
                            4. 申请退票的影票未被取票；<br/>
                            5. 因影院售票系统原因造成的用户已完成付款但订单无法生成的（即订单状态为"不成功"的）；<br/>
                            6. 因影院原因造成的影片排片信息、场次更换而使得用户无法正常观影的；<br/>
                            7. 因不可抗力或政府管制（如全国哀悼日，国家禁止娱乐活动的）造成用户无法正常观影的。`,
            },
            {
              listTitle: '二、用户同意以下卖座网的不予退款的条件及约定:',
              listContext: `1. 订购成功，以收到短信，订单状态为"成功"为准，用户一旦购买成功，是订座票这种情况的，卖座网不接受任何退款或换票；<br>
                            2. 卖座网或卖座网与合作方赠送的站内余额不作为退款范畴内；<br>
                            3. 卖座卡充值等不作为退款范畴内；<br>
                            4. 恶意用户及黄牛不支持退票，退票服务是为卖座网正常购票用户提供的服务，卖座网严禁用户恶意使用信用卡套现的行为，一经卖座网发现，卖座网有权冻结该用户账户，同时有权通报给该信用卡发卡单位；<br>
                            5. 卖座网保留部分消费卡、银行卡支付，向用户索取支付手续费或第三方合作服务费的权利；<br>
                            6. 用户遵守卖座网对退款的相关规定，并允许卖座网保留对规定更新或更改的权利。 以上退款原则涵盖于卖座网自由渠道以及合作渠道以及第三方代理机构，卖座网保留解释权。`,
            },
            {
              listTitle: '三、申请退票方式:',
              listContext: `1. 只接受用户电话退票申请，不接受其他退票申请方式；<br>
                            2. 只限购票用户本人来电申请，卖座网将核对申请用户信息；<br>
                            3. 拨打客服热线：400-1808-400，客服为您进行退票操作。`,
            },
          ],
        },
        {
          flag: 'C',
          title: '服务条款',
          list: [
            {
              listTitle: '一、服务条款的确定和接受',
              listContext: `《服务条款》（以下简称"本条款"）由深圳市华宇讯科技有限公司在提供域名为 www.maizuo.com 卖座网的网站服务时与卖座网用户达成的关于使用卖座网的各项规则、条款和条件。用户点击"同意"选项完成注册，这表示用户已接受本服务条款，并与公司就本服务条款达成协议。`,
            },
            {
              listTitle: '二、内容所有权及知识产权',
              listContext: `1.卖座网提供的网络服务内容包括但不限于文字、软件、声音、图片、图表、视频等。所有这些内容受版权、商标和其他财产所有权法律的保护。<br/>
                            2.卖座网手机版及手机客户端的各种版本，包括但不限于wap版、Iphone版、Android版等，均援引适用本服务条款。卖座网拥有并保留其提供的各种手机客户端及在手机客户端中展示内容的全部知识产权。<br/>
                            3.用户只能在公司的授权下才能使用这些内容，而不能擅自复制、改造、创造与这些内容有关的派生产品。`,
            },
            {
              listTitle: '三、用户服务的使用与停止使用',
              listContext: `1.卖座网运用自己的操作系统通过国际互联网络为用户仅提供相关的网络服务。<br/>
                            用户必须：<br/>
                            (a) 自备与网络服务有关的设备（如电脑.调制解调器及其他与接入互联网有关的装置等）；<br/>
                            (b) 上网和其他获取此服务而支出所需的费用（如为接入互联网而支付的电话费及上网费等）均应由用户自行承担。<br/>
                            2.考虑到公司产品服务的重要性和商品属性。在使用卖座网服务时，用户同意：<br/>
                            (a) 注册时，提供及时、详尽及准确的个人资料；<br/>
                            (b) 用户名和昵称的注册与使用应符合网络道德，遵守中华人民共和国的相关法律法规；<br/>
                            (c) 用户名和昵称中不能含有威胁、淫秽、漫骂、非法、侵害他人权益等有争议性的文字；<br/>
                            (d) 如果用户提供的资料包含不当的信息，卖座网保留结束该用户使用服务资格的权利。<br/>
                            (e) 用户须对其账户中的所有行为、活动和事件负全责。<br/>
                            3.用户不得通过不正当手段恶意注册卖座网账号，不得盗用他人账号，不得泄露其他用户的相关信息。如用户违反上述规定，则卖座网有权直接采取一切必要的措施，包括但不限于删除用户发布的内容、暂停或查封用户账号，取消因违规所获利益，乃至通过诉讼形式追究用户法律责任等。<br/>
                            4.用户有权随时对自己的个人资料进行查询、修改和删除；也可以停止使用旧的帐户重开一个新帐户；用户若发现任何非法使用用户账号或安全漏洞的情况，应当立即与卖座网联系。`,
            },
            {
              listTitle: '四、用户从事商业性行为',
              listContext: `未经卖座网的授权或许可，任何用户不得借用卖座网的名义从事任何商业活动，也不得将卖座网作为从事商业活动的场所、平台或其他任何形式的媒介。禁止用户将本站用作从事各种非法活动的场所、平台或者其他任何形式的媒介。如用户违反上述规定，则卖座网有权直接采取一切必要的措施，包括但不限于删除用户发布的内容、暂停或查封用户账号，乃至通过诉讼形式追究用户法律责任等。`,
            },
            {
              listTitle: '五、用户资料隐私保护',
              listContext: `1.对用户注册信息卖座网提供最大限度的安全保障。同时，用户务必对其用户密码、个人账号等信息保密，以免被盗用或遭窜改。注册成功后，用户必须保护好自己的账号和密码，对非因卖座网原因的泄露而产生的损失，卖座网概不负责。<br/>
                            2.本条款所称之用户隐私包括被法律确认为隐私内容，并符合下述范围的信息：<br/>
                            (a) 用户注册卖座网时根据网站要求提供的个人信息；<br/>
                            (b) 在用户使用卖座网服务、参加网站活动、或访问网站网页时，网站自动接收并记录的用户浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及用户要求取用的网页记录；<br/>
                            (c) 卖座网通过合法途径从商业伙伴处取得的用户个人资料。<br/>
                            3.用户只能在公司的授权下才能使用这些内容，而不能擅自复制、改造、创造与这些内容有关的派生产品。<br/>
                            4.在不透露单个用户隐私资料的前提下，卖座网有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。`,
            },
            {
              listTitle: '六、用户管理',
              listContext: `1.用户不得利用本网站危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益，不得利用本网站制作、复制和传播下列信息：<br/>
                            (a) 煽动抗拒、破坏宪法和法律、行政法规实施的；<br/>
                            (b) 煽动颠覆国家政权，推翻社会主义制度的；<br/>
                            (c) 煽动分裂国家、破坏国家统一的；<br/>
                            (d) 煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
                            (e) 捏造或者歪曲事实，散布谣言，扰乱社会秩序的；<br/>
                            (f) 宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；<br/>
                            (g) 公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；<br/>
                            (h) 损害国家机关信誉的；<br/>
                            (i) 其他违反宪法和法律行政法规的；<br/>
                            (j) 进行商业广告行为的。`,
            },
            {
              listTitle: '七、退款原则',
              listContext: `1.卖座网仅提供因以下原因造成的用户退票：<br/>
                            (a) 因影院售票系统原因造成的用户已完成付款但订单无法生成的（即订单状态为"不成功"的）；<br/>
                            (b) 因影院原因造成的影片排片信息、场次更换而使得用户无法正常观影的；<br/>
                            (c) 因不可抗力或政府管制（如全国哀悼日，国家禁止娱乐活动的）造成用户无法正常观影的。<br/>
                            2.用户同意以下卖座网的不予退款的条件及约定:<br/>
                            (a) 订购成功，以收到短信，订单状态为"成功"为准，用户一旦购买成功，是订座票这种情况的，卖座网不接受任何退款或换票；<br/>
                            (b) 卖座网或卖座网与合作方赠送的站内余额不作为退款范畴内；<br/>
                            (c) 卖座卡充值等不作为退款范畴内；<br/>
                            (d) 卖座网严禁用户恶意使用信用卡套现的行为，一经卖座网发现，卖座网有权冻结该用户账户，同时有权通报给该信用卡发卡单位；<br/>
                            (e) 卖座网保留部分消费卡、银行卡支付，向用户索取支付手续费或第三方合作服务费的权利；<br/>
                            (f) 用户遵守卖座网对退款的相关规定，并允许卖座网保留对规定更新或更改的权利。`,
            },
            {
              listTitle: '八、服务变更、中断或终止',
              listContext: `1.卖座网因自身产品、合作伙伴变更等情形而引起的提供服务的变更，卖座网享有变更权，包括但不限于：<br/>
                            (a) 卖座网有随时中止或终止与影院合作的权利；<br/>
                            (b) 卖座网有随时调整服务费收取标准的权利；<br/>
                            (c) 卖座网有由于影院系统、服务器故障等原因，为用户调换座位、场次或影院的权利；<br/>
                            (d) 卖座网有按照卖座网退票原则为用户退票的权利；<br/>
                            (e) 卖座网有更改卖座网影券使用权限的权利；<br/>
                            (f) 卖座网有更改卖座网积分使用规则的权利；<br/>
                            (g) 卖座网有更改卖座网积分兑换产品的权利；<br/>
                            (h) 卖座网有更换购票流程、购票方式、取票流程的权利。<br/>
                            (i) 用户同意卖座网可随时行使以上变更权，并接受卖座网变更后所提供的服务。<br/>
                            2.卖座网会不定期的举行诸如"抢票"的优惠购票活动，用户理解并同意将使用合理正当的方式参与此类活动，同时由于系统拥堵或系统延时等原因而造成的各种问题，用户同意以卖座网的处理方式及解决办法为准。若卖座网一旦发现有用户使用不正当手段参与此类活动，卖座网有权追究该用户的责任，包括但不限于取消订单、冻结账户等。<br/>
                            3.如因系统维护或升级的需要而需暂停网络服务，卖座网将尽可能事先进行通告。<br/>
                            4.如发生下列任何一种情形，卖座网有权随时中断或终止向用户提供本条款项下的网络服务而无需通知用户：<br/>
                            (a) 用户提供的个人资料不真实；<br/>
                            (b) 用户违反本条款中规定的使用规则。<br/>
                            5.卖座网因下列系统原因或不可抗力引起的服务中断、延时或终止，使用户无法正常使用卖座网部分或全部服务时，卖座网不承担损害赔偿责任，该状况包括但不限于:<br/>
                            (a) 卖座网在网站公告的系统停机维护期间；<br/>
                            (b) 因电信设备出现故障不能进行数据传输的；<br/>
                            (c) 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成卖座网系统障碍不能正常提供服务的；<br/>
                            (d) 由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。<br/>
                            6.除前款所述情形外，卖座网同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络服务的权利，对于所有服务的中断或终止而造成的任何损失，卖座网无需对用户或任何第三方承担任何责任`,
            },
            {
              listTitle: '九、免责声明',
              listContext: `1.由于卖座网网站信息来自于不同渠道，所有信息仅作为参考和建议之用，一切以现实情况为准。用户明确同意其使用卖座网网络服务所存在的风险将完全由其自己承担；因其使用卖座网网络服务而产生的一切后果也由其自己承担，卖座网对用户概不负责。<br/>
                            2.卖座网不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。<br/>
                            3.卖座网不声明或保证本网站的材料或服务是准确的、完整的、可靠的、当前的或无差错的，并且否认有关本网站、本网站内容或其任何部分的准确性或专有性的任何保证或声明。 卖座网对与服务定价、文本或视频有关的错误或疏忽不负责。<br/>
                            4.虽然卖座网力图使用户能对本网站和服务进行安全访问和使用，但卖座网不能也不会声明或保证本网站或其服务器是不含病毒或其它有害因素的；因此卖座网建议用户使用业界公认的杀毒软件以保证自身的使用安全。<br/>
                            5.任何卖座网手机客户端的手机品牌合作商，如Apple、AppStore，并不因合作获得卖座网的知识产权，因卖座网手机客户端或手机客户端中展示的内容侵犯任何第三方知识产权均由卖座网承担相应的法律责任。<br/>
                            6.用户选择的银联、第三方支付等多种支付方式，在支付过程中因用户自身原因或第三方支付平台原因造成的异常使其账户无法正常使用或遭受损失，卖座网对此不承担责任，一切损失由用户自行承担。<br/>
                            7.因用户自身原因或影院或影院售票系统服务商原因造成的异常状况下的调换座位，卖座网对此状况免责，包括但不限于：<br/>
                            (a) 因放映影院、影厅、放映时长的变更而引起的座位调换；<br/>
                            (b) 因影院音响音效、画面质量、影片质量引起的座位调换；<br/>
                            (c) 因用户未保留好自己的取票码引起的座位调换；<br/>
                            (d) 因用户取票后丢失票券引起的座位调换；<br/>
                            (e) 因影院售票系统提供商的服务器不稳定引起的座位调换；<br/>
                            (f) 因影院工作人员或服务人员的服务引起的座位调换；<br/>
                            (h) 因用户提出的高于影院服务标准的要求引起的座位调换。`,
            },
            {
              listTitle: '十、违约赔偿',
              listContext: `用户同意保障和维护卖座网及其他用户的利益，如因用户违反有关法律、法规或本条款项下的任何条款而给卖座网或任何其他第三人造成损失，用户同意承担由此造成的全部损害赔偿责任。`,
            },
            {
              listTitle: '十一、修改条款',
              listContext: `1.卖座网将在必要时修改本条款的有关条款，一旦条款内容发生变动，卖座网将会在相关的页面提示修改内容。<br/>
                            2.如果不同意卖座网对服务条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受服务条款的变动。`,
            },
            {
              listTitle: '十二、法律管辖',
              listContext: `1.本服务条款的订立、执行和解释及争议的解决均应适用中国法律。<br/>
                            2.用户同意如用户与卖座网就本条款内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向卖座网所在地的人民法院提起诉讼。`,
            },
            {
              listTitle: '十三、其他规定',
              listContext: `1.如果本服务条款中的某些规定被视为不合法、无效或因任何原因而无法执行，则此等规定应视为可从本网站条款分割，且不得影响任何其余规定的效力和可执行性。<br/>
                            2.本条款是卖座网与用户之间的法律关系的重要文件，如用户的任何书面或者口头意思表示与本条款不一致的，均应当与本条款为准，除非本条款被卖座网声明作废或者被新版本代替。<br/>
                            3.如果用户对卖座网或本服务条款存有任何疑问，可以联系我们。<br/>
                            4.在法律允许的范围内，深圳市华宇讯科技有限公司保留修改完善本条款的权利。当社区秩序受到重大威胁或者本条款的实施遇到重大障碍时，深圳市华宇讯科技有限公司可以暂停或者终止本条款的实施，并颁行临时管理措施。<br/>
                            5.本条款自发布之日起生效。`,
            },
          ],
        },
      ],
      banners: [],
    }
  },
  mounted() {
    const self = this
    axios
      .get('/api/banner')
      .then(function(res) {
        console.log(res)
        if (res.status === 200) {
          self.banners = res.data.data
        }
      })
      .catch(function(error) {
        // handle error
        console.log(error)
      })
    // this.banners = ['https://static-kaop.sdyxmall.com/upload/kevenzhibo.jpg', 'https://mall.s.maizuo.com/ec15f7be2afc707f29cad4423809d9f7.jpg']
  },
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    showSwiper() {
      return this.banners.length > 0
    },
  },
  methods: {
    prevPage() {
      console.log(this.$refs.mySwiper)
      this.swiper.slidePrev(300)
    },
    nextPage() {
      this.swiper.slideNext(300)
    },
  },
}
</script>

<style lang="less">
.home {
  .banner_container {
    background: -webkit-linear-gradient(left top, #f18130, #fc4b01);
    position: relative;
  }
  .banner {
    min-height: 480px;
    width: 100%;
    // background: url('https://mall.s.maizuo.com/ec15f7be2afc707f29cad4423809d9f7.jpg') no-repeat center top;
    overflow: hidden;
  }
  .banner img {
    min-height: 480px;
    width: 100%;
    background-position: center center;
    overflow: hidden;
    display: block;
  }
  .my-swiper-pagination {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    img {
      width: 22px;
      height: 36px;
    }
  }
  .about_us {
    width: 100%;
    padding-bottom: 54px;
    border-bottom: 1px solid #e6e6e6;
    .container {
      width: 980px;
      margin: 0 auto;
      margin-top: 48px;
      h1 {
        margin: 0;
        color: #222426;
        font-size: 36px;
        font-weight: normal;
      }
      p {
        font-size: 16px;
        color: #6e6e6e;
        font-weight: normal;
      }
      table {
        width: 100%;
        font-size: 0;
      }
      .show_img {
        width: 300px;
        height: 180px;
        margin: 0;
      }
    }
  }
  .content {
    width: 100%;
    padding-bottom: 130px;
    .container {
      width: 980px;
      padding-top: 60px;
      margin: 0 auto;
      overflow: hidden;
      .changeMd {
        width: 154px;
        height: 238px;
        margin-right: 70px;
        float: left;
        border: 1px solid #d2d2d2;
        border-radius: 12px;
        padding: 44px 0;
        ul {
          margin: 0;
          padding: 0;
          li {
            width: 100%;
            text-align: center;
            list-style: none;
            line-height: 50px;
            font-size: 16px;
            color: #4a4a4a;
            cursor: pointer;
            &:hover {
              animation: suofang 0.5s;
            }
            @keyframes suofang {
              0% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.1);
              }
              100% {
                transform: scale(1);
              }
            }
          }
          .isActive {
            color: #ff5f16;
            transition: color 0.5s;
          }
        }
      }
      .show_md {
        width: 750px;
        float: left;
        h1 {
          margin: 0;
          color: #222426;
          font-size: 36px;
          margin-bottom: 28px;
          font-weight: normal;
        }

        .slide-transition {
          transition: all 1s ease;
        }

        .show-enter-active {
          transition: all 1s ease;
        }
        .show-leave-active {
          transition: all 1s ease;
        }
        .show-enter,
        .show-leave-active {
          transform: translateY(20px);
          opacity: 0;
        }
        .show-leave-active {
          display: none;
        }
        .md_one {
          ul {
            margin: 0;
            padding: 0;
            li {
              list-style: none;
              margin-top: 10px;
              overflow: hidden;
              .num {
                display: block;
                float: left;
                margin-top: 3px;
                margin-right: 10px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #6d6d6d;
                color: #fff;
                font-size: 14px;
                text-align: center;
                line-height: 20px;
                font-style: normal;
              }
              .listTitle {
                color: #222426;
                font-size: 18px;
              }
              p {
                font-size: 14px;
                color: #6e6e6e;
                padding-left: 30px;
                font-weight: normal;
              }
            }
          }
        }
        .md_two,
        .md_three {
          ul {
            margin: 0;
            padding: 0;
            li {
              list-style: none;
              overflow: hidden;
              margin-bottom: 30px;
              .listTitle {
                color: #ff5f16;
                font-size: 18px;
                font-weight: bold;
              }
              p {
                font-size: 14px;
                color: #787878;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}
</style>
